import React from 'react';
import '../../styles/Landing.css'; 
import '../../assets/fonts/Megapunch_Italic.ttf';
import '../../assets/fonts/pressStart.ttf';
import { Link } from 'react-router-dom';
import cltcolor from '../../assets/images/logo_clt.gif';


export default function Landing () {
  return (
    <div className='clt-tube-border'>
        <img src={cltcolor} alt='enjoy the ride clt gif' style={spinningGifStyles}></img>
        <Link to='/home' style={{textDecoration:'none'}}>
            <h1 style={pressStartStyles} className='blinking'>PRESS START</h1>
        </Link>
        <p style={copyrightStyles}> © CLT 2024</p>
    </div>
  )
}

const titleStyles = {
    fontFamily: 'PressStart',
    fontSize: '18px',
    color: "#F5F5F5",
    top: '15%',
    left:'50%',
    transform: 'translateX(-50%)',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
}

const pressStartStyles = {
    fontFamily: 'PressStart',
    fontSize: '25px',
    color: "black",
    display: 'flex',
    justifyContent: 'center',
    top: '55%',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    alignItems: 'center',
  }
  
  const copyrightStyles = {
    fontFamily: 'PressStart',
    fontSize: '1em',
    bottom: '10%',
    left: '50%',
    transform: 'translateX(-50%)',
    color: 'black',
    position: 'absolute',
  }
  
  const spinningGifStyles = {
    top: '20%',
    left: '50%',
    transform: 'translateX(-50%)',
    position: 'absolute',
    height: '30vh',
    width: 'auto',
  }