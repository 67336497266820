import React from 'react'
import { useNavigate } from 'react-router-dom';
import '../../styles/Credits.css';
import gifFest from '../../assets/images/spinning_fest.gif';

export default function Credits() {
  const igNma = 'https://www.instagram.com/nma1._/';
  const igRams = 'https://www.instagram.com/ramsford__/';
  const igPhil = 'https://www.instagram.com/phi_mhp/';
  const igArez = 'https://www.instagram.com/areztation/';
  const igSabri = 'https://www.instagram.com/sabrinessaibia/';
  const igGrizzy = 'https://www.instagram.com/grizzyy.y/';
  const igAkalex = 'https://www.instagram.com/akalexmusic/';
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  const handleIgLink = (igUrl) => {
    const userAgent = navigator.userAgent || window.opera;
    const username = igUrl.split('/').filter(Boolean).pop();

    if (/android/i.test(userAgent)) {
      window.location.href = `intent://instagram.com/_u/${username}#Intent;package=com.instagram.android;scheme=https;end`;
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = `instagram://user?username=${username}`;
    } else {
      window.open(igUrl, '_blank');
    }
  };
  return (
    <div className='clt-tube-border'>
      <a onClick={handleBack} style={{ textDecoration: 'none' }}>
        <img src={gifFest} alt='enjoy the ride clt gif' style={spinningGifStyles}></img>
      </a>
      <div style={contentContainerStyles}>
        <p style={contentStyles} onClick={() => handleIgLink(igArez)}>AREZKI MESSAOUDI-DIRECTOR</p>
        <p style={contentStyles} onClick={() => handleIgLink(igSabri)}>SABRI NESSAIBIA-DIRECTOR</p>
        <p style={contentStyles} onClick={() => handleIgLink(igGrizzy)}>GRIZZY-DIRECTOR</p>
        <p style={contentStyles} onClick={() => handleIgLink(igAkalex)}>AKALEX-DIRECTOR</p>
        <p style={contentStyles} onClick={() => handleIgLink(igNma)}>NICOLAS MARIE-ANGELIQUE-DESIGNER</p>
        <p style={contentStyles}onClick={() => handleIgLink(igRams)}>RAMSFORD SHEAMANG-DEVELOPER</p>
        <p style={contentStyles} onClick={() => handleIgLink(igPhil)}>PHILIPPE MAHAPATRA-DEVELOPER</p>
        {/* <p style={copyrightStyles}> © CLT 2023</p> */}
      </div>
    </div>
  )
}

const containerStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const contentContainerStyles = {
  textAlign: 'center',
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translateX(-50%)',
}

const contentStyles = {
  fontFamily: 'PressStart',
  fontSize: '15px',
  color: "#F5F5F5",
}

const spinningGifStyles = {
  top: '7%',
  left: '50%',
  transform: 'translateX(-50%)',
  position: 'absolute',
  height: '25vh',
  width: 'auto',
}

const copyrightStyles = {
  fontFamily: 'PressStart',
  fontSize: '1em',
  bottom: '10%',
  left: '50%',
  transform: 'translateX(-50%)',
  color: '#F5F5F5',
  position: 'absolute',
}