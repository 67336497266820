import React from 'react'
import { useNavigate } from 'react-router-dom';
import '../../styles/About.css';
import cltcolor from '../../assets/images/logo_clt.gif';
import '../../assets/fonts/Megapunch_Italic.ttf';
import '../../assets/fonts/pressStart.ttf';

export default function About() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div className='clt-tube-border'>
      <a onClick={handleBack} style={{ textDecoration: 'none' }}>
        <img src={cltcolor} alt='enjoy the ride clt gif' style={spinningGifStyles}></img>
      </a>
      <div style={contentContainerStyles}>
        <p style={contentStyles}>{text}</p>
      </div>
    </div>
  )
}

const text = "CLT IS AN INDEPENDENT ONLINE RADIO STATION AND A MUSIC EVENT CURATION PLATFORM BASED IN MULHOUSE, FRANCE. WE ARE A CREATIVE COMMUNITY OF MUSIC LOVERS THAT CELEBRATE LIFE IN A DIFFERENT WAY, IN MULHOUSE & BEYOND. WE BROADCAST, WE PRODUCE EVENTS, WE PUSH THE CULTURE. WE THINK BIG, WE THINK 360°. WE EMPOWER ACCEPTANCE AND JOY, ACCEPT PEOPLE FROM ALL BACKGROUNDS PARTYING AND SHARING MEMORABLE MOMENTS TOGETHER, THAT’S OUR SOCIETY.";

const contentContainerStyles = {
  textAlign: 'center',
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '70%',
}

const contentStyles = {
  fontFamily: 'PressStart',
  fontSize: '1em',
  color: "black",
  textAlign: 'center',
  texttTransform: 'uppercase',
}

const spinningGifStyles = {
  top: '8%',
  left: '50%',
  transform: 'translateX(-50%)',
  position: 'absolute',
  height: '30vh',
  width: 'auto',
}