import React from 'react'
import { useNavigate } from 'react-router-dom';
import '../../styles/LineUp.css';
import cltcolor from '../../assets/images/logo_clt.gif';

export default function LineUp() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div className='clt-tube-border'>
      <a onClick={handleBack} style={{ textDecoration: 'none' }}>
        <img src={cltcolor} alt='enjoy the ride clt gif' style={spinningGifStyles}></img>
      </a>
      <div style={contentContainerStyles}>
        <div style={contentStyles}>CLT CREW</div>
      </div>
    </div>
  )
}

const contentContainerStyles = {
  textAlign: 'center',
  position: 'absolute',
  top: '35%',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '70%',
  lineHeight: '1.32em',
}

const contentStyles = {
  fontFamily: 'PressStart',
  fontSize: '1em',
  color: "black",
}

const spinningGifStyles = {
  top: '7%',
  left: '50%',
  transform: 'translateX(-50%)',
  position: 'absolute',
  height: '30vh',
  width: 'auto',
}